import ApplicationController from './application_controller'

import Sortable from "sortablejs";

export default class extends ApplicationController {

  connect () {
    super.connect()

    var nestedSortables3 = document.querySelectorAll(".nested-sortable-3");
    for (var i = 0; i < nestedSortables3.length; i++) {
      new Sortable(nestedSortables3[i], {
        group: 'nested-3',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        onEnd: this.end.bind(this),
        filter: '.filtered'
      });
    }

    var newTopic = document.querySelector("#new-topic");
    new Sortable(newTopic, {
      group: {
          name: 'nested-3',
          pull: 'clone',
          put: false // Do not allow items to be put into this list
      },
      animation: 150,
      sort: false,
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    this.sort(event);
  }

  sort(event) {
    let topics_el = document.querySelectorAll('.nested-sortable-3 .topic');
    let topics = Array.from(topics_el).map((topic, index) => {
        return {id: topic.dataset.topicId, order: (index + 1), section_id: topic.parentElement.dataset.sectionId, show: (topic.parentElement.dataset.show == 'true'), hide_title: topic.dataset.hideTitle}
    });

    var data = JSON.stringify(topics);

    this.stimulate("TopicsReflex#sort", data);
  }

}
