import ApplicationController from './application_controller'

import Sortable from "sortablejs";

export default class extends ApplicationController {

  connect () {
    super.connect()

    var nestedSortables1 = document.querySelectorAll(".nested-sortable-1");
    for (var i = 0; i < nestedSortables1.length; i++) {
      new Sortable(nestedSortables1[i], {
        group: 'nested-1',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        onEnd: this.end.bind(this)
      });
    }

    var newChapter = document.querySelector("#new-chapter");
    new Sortable(newChapter, {
      group: {
          name: 'nested-1',
          pull: 'clone',
          put: false // Do not allow items to be put into this list
      },
      animation: 150,
      sort: false,
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
      this.sort(event);
  }

  sort(event) {
    let chapters_el = document.querySelectorAll('.nested-sortable-1 .chapter');
    let chapters = Array.from(chapters_el).map((chapter, index) => {
        return {id: chapter.dataset.chapterId, order: (index + 1), plan_id: chapter.dataset.planId}
    });

    console.log(JSON.stringify(chapters))

    var data = JSON.stringify(chapters);

    this.stimulate("ChaptersReflex#sort", data);
  }

}
