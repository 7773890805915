import ApplicationController from './application_controller'

import Sortable from "sortablejs";

export default class extends ApplicationController {

  connect () {
    super.connect()

    var nestedSortables2 = document.querySelectorAll(".nested-sortable-2");
    for (var i = 0; i < nestedSortables2.length; i++) {
      new Sortable(nestedSortables2[i], {
        group: 'nested-2',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        onEnd: this.end.bind(this),
        filter: '.filtered'
      });
    }

    var newSection = document.querySelector("#new-section");
    new Sortable(newSection, {
      group: {
          name: 'nested-2',
          pull: 'clone',
          put: false // Do not allow items to be put into this list
      },
      animation: 150,
      sort: false,
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    this.sort(event);
  }

  sort(event) {
    let sections_el = document.querySelectorAll('.nested-sortable-2 .section');
    let sections = Array.from(sections_el).map((section, index) => {
        return {id: section.dataset.sectionId, order: (index + 1), chapter_id: section.parentElement.dataset.chapterId}
    });

    console.log(JSON.stringify(sections))

    var data = JSON.stringify(sections);

    this.stimulate("SectionsReflex#sort", data);
  }

}
