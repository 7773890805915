import ApplicationController from './application_controller'

import Sortable from "sortablejs";

export default class extends ApplicationController {

  connect () {
    super.connect()
    
    var nestedSortables4 = document.querySelectorAll(".nested-sortable-4");
    for (var i = 0; i < nestedSortables4.length; i++) {
      new Sortable(nestedSortables4[i], {
        group: 'nested-4',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        onEnd: this.end.bind(this),
        filter: '.filtered'
      });
    }
  }

  end(event) {
    this.sort(event);
  }

  sort(event) {
    let table = document.querySelector('.nested-sortable-4')
    let forecast_elements_el = table.querySelectorAll('.forecast_element');
    let forecast_elements = Array.from(forecast_elements_el).map((forecast_element, index) => {
        return {id: forecast_element.dataset.forecastElementId, order: (index + 1)}
    });

    this.stimulate("ForecastElementsReflex#sort", JSON.stringify(forecast_elements), table.dataset.modelName);
  }
}
